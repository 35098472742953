:root {
    --ds-color-primary: #28f0d9;
    --ds-color-secondary: #004750;
    --ds-color-white: #ffffff;
    --ds-color-special: #00e696;
    --ds-color-root: #e66800;

    --ds-color-primary-hover: #23d3bc;
    --ds-color-primary-active: #38f1db;

    --ds-color-secondary-hover: #02383f;
    --ds-color-secondary-active: #066572;

    --ds-color-special-hover: #09ce8b;
    --ds-color-special-active: #4bffbb;

    --ds-color-n1: #000000;
    --ds-color-n2: #343a3f;
    --ds-color-n3: #697077;
    --ds-color-n4: #a2a9b0;
    --ds-color-n5: #cccccc;
    --ds-color-n6: #e5e5e5;
    --ds-color-n7: #f2f2f2;

    --ds-color-section-hg: #f2f2f2;

    --ds-color-danger: #e60000;
    --ds-color-warning: #fca603;
    --ds-color-success: #0dad07;

    --ds-border-radius-small: 0.375rem;
    --ds-border-radius-normal: 1.5em;
    --ds-box-shadow: 0 0rem 0.3rem rgba(0, 0, 0, 0.13);

    --ds-ci-font: 'Inter', Arial, sans-serif;
    --ds-ci-font-loader: 'Flow Circular', Arial, sans-serif;

    --ds-color-black: #212529;

    --bs-tooltip-bg: red;

    --ds-gradient-colors-startday: #fff 0%, #fff 50%, #00e696 51%;
    --ds-gradient-colors-endday: #00e696 0%, #00e696 50%, #fff 51%;
    --ds-gradient-colors-startday-endday: #fff 0%, #fff 30%, #00e696 50%,
        #fff 70%, #fff 100%;
}
