/* inter-regular - latin-ext_latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/inter/inter-v8-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/inter/inter-v8-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/inter/inter-v8-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/inter/inter-v8-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/inter/inter-v8-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/inter/inter-v8-latin-ext_latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
    font-display: optional;
}

/* inter-600 - latin-ext_latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/inter/inter-v8-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/inter/inter-v8-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/inter/inter-v8-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/inter/inter-v8-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/inter/inter-v8-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/inter/inter-v8-latin-ext_latin-600.svg#Inter') format('svg'); /* Legacy iOS */
    font-display: optional;
}

/* flow-circular-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Flow Circular';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/flow-circular/flow-circular-v7-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/flow-circular/flow-circular-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/flow-circular/flow-circular-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/flow-circular/flow-circular-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/flow-circular/flow-circular-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/flow-circular/flow-circular-v7-latin-regular.svg#FlowCircular') format('svg'); /* Legacy iOS */
}
